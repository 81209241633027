import gitbook from 'images/Social/gitbook.svg';
import twitter from 'images/Social/twitter.svg';
import discord from 'images/Social/discord.svg';
import medium from 'images/Social/medium.svg';
import github from 'images/Social/github.svg';

export const social = [
  { image: gitbook, link: 'https://docs.insurefinance.xyz/' },
  { image: twitter, link: 'https://twitter.com/insure_finance' },
  { image: discord, link: 'https://discord.gg/V3NkJAGZjU' },
  { image: medium, link: 'https://medium.com/@insurefinance' },
  { image: github, link: 'https://github.com/insuredefi' },
];

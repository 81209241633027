import { Timeline } from '@mui/lab';
import { styled } from '@mui/material';

export const CustomizedTimeline = styled(Timeline)(
  ({ theme }) => `
    @media only screen and (max-width: 900px) {
        & .MuiTimelineItem-root {
          flex-direction: row;
        }
        & .MuiTimelineItem-root:before {
            display: none;
        }
    }
  `
);

import { styled } from '@mui/material';

export const CircleImage = styled('img')(
  () => `
    background-color: #34384c;
    height: 70px;
    padding: 20px;
    border-radius: 8px;
  `
);

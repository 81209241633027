import { createTheme } from '@mui/material/styles';

export const defaultTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#1d4ed8',
    },
    secondary: {
      main: '#18191d',
    },
    error: {
      main: '#8d14ff',
    },
    text: {
      primary: '#ffffff',
      secondary: '#a1a1aa',
    },
    background: {
      default: '#000000',
    },
  },
  typography: {
    allVariants: {
      color: '#ffffff',
    },
    fontFamily: ['DM sans', 'sans-serif'].join(','),
    h1: {
      fontSize: 42,
      fontWeight: 700,
    },
    h2: {
      fontSize: 34,
      fontWeight: 700,
    },
    h3: {
      fontSize: 20,
      fontWeight: 700,
    },
    body1: {
      fontSize: 16,
      fontWeight: 500,
    },
    body2: {
      fontSize: 15,
      fontWeight: 400,
    },
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#ffffff',
          '&.Mui-focused': {
            color: '#ffffff',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          fontSize: 14,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 600,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 10,
        },
      },
    },
  },
});

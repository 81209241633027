export const roadmap = [
  {
    header: 'Development',
    content: ['Smart contract development', 'Initial UI'],
  },
  {
    header: 'Testnet Launch',
    content: ['Stabilize deployments', 'Test critical features', 'Prepare contracts for audit'],
  },
  {
    header: 'Mainnet Launch',
    content: ['Prepare initial liquidity', 'Launch on Mainnet'],
  },
  {
    header: 'Expansion',
    content: ['Support for more assets', 'Integration & partnership'],
  },
  {
    header: 'Decentralization',
    content: ['Setup protocol treasury', 'Decentralization of payouts'],
  },
];

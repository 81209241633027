import { Box, Grow, Paper, Typography } from '@mui/material';
import * as React from 'react';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useRef } from 'react';
import { useIntersection } from 'hooks/useIntersection';
import { TimelineItem } from '@mui/lab';

interface TimelineItemProps {
  header: string;
  content: string[];
}

interface TimelineProps {
  item: TimelineItemProps;
  index: number;
}

export const TimelineElem: React.FC<TimelineProps> = (props) => {
  const { item } = props;

  const ref = useRef();
  const inViewport = useIntersection(ref, '-50px');

  return (
    <Grow in={inViewport} timeout={1000}>
      <TimelineItem ref={ref} position={props.index % 2 ? 'left' : 'right'}>
        <TimelineSeparator>
          <TimelineDot color="primary" />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper sx={{ padding: 3, backgroundColor: '#23262f' }}>
            <Typography variant="h3" textAlign="left" marginBottom={1.5} fontSize="22px">
              {item.header}
            </Typography>
            {item.content.map((content, index) => (
              <Box key={`key-${index}`} display="flex" alignItems="baseline" gap={1}>
                <FiberManualRecordIcon style={{ width: 12, height: 12, color: '#a1a1aa' }} />
                <Typography
                  variant="body2"
                  fontSize="18px"
                  color="text.secondary"
                  sx={{ textAlign: 'left !important' }}
                >
                  {content}
                </Typography>
              </Box>
            ))}
          </Paper>
        </TimelineContent>
      </TimelineItem>
    </Grow>
  );
};

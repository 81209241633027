import { Button, styled } from '@mui/material';

export const CustomizedButton = styled(Button)(
  ({ theme }) => `
    color: ${theme.palette.text.primary};
    background-color: ${theme.palette.primary.main};
    padding: 6px 20px;
    border-radius: 8px;
    font-size: 15px;
    &:hover {
      background-color: ${theme.palette.primary.dark};
    }
    margin:0 auto;
  `
);

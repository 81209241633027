import { Grid, Typography } from '@mui/material';
import * as React from 'react';
import { ecosystem } from 'content/Ecosystem';
import { CustomizedContainer } from 'theme/customizedcontainer';
import { CircleCard } from 'components/CircleCard/CircleCard';

export const Ecosystem: React.FC = () => {
  return (
    <CustomizedContainer maxWidth="lg">
      <Grid container spacing={10}>
        <Grid item md={5} xs={12} display="flex" justifyContent="center" flexDirection="column">
          <Typography variant="h1">Protocol stakeholders</Typography>
          <Typography variant="body1" color="text.secondary" sx={{ marginTop: 2 }}>
            Earn yield by providing liquidity or buy insurance and protect yourself from drastic crypto devaluation
          </Typography>
        </Grid>
        <Grid item md={7} xs={12}>
          {ecosystem.map((item, index) => (
            <Grid
              key={`key-${index}`}
              container
              flexDirection="column"
              alignItems={{ md: index === 0 ? 'flex-start' : 'flex-end' }}
              marginBottom={index === 0 ? '2rem' : ''}
            >
              <Grid item md={6} xs={12} display="flex" justifyContent="center">
                <CircleCard item={item} />
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </CustomizedContainer>
  );
};

import investor from 'images/Benefits/investor.svg';
import guaranteeCertificate from 'images/Benefits/guarantee-certificate.svg';

export const ecosystem = [
  {
    header: 'Buyers',
    text: 'Buy policy and protect yourself from drastic devaluation of crypto assets.',
    image: investor,
  },
  {
    header: 'Underwriters',
    text: 'Provide liquidity to earn yield generated through premium paid by the insurance buyers.',
    image: guaranteeCertificate,
  },
];

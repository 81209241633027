import { styled } from '@mui/material';

export const SectionImage = styled('img')(
  ({ theme }) => `
    height: 70%;
    width: 70%;
    background-size: cover;
    @media only screen and (max-width: 900px) {
        & {
          margin-top: 2rem;
          height: 60%;
          width: 60%;
        }
    }
    @media only screen and (max-width: 680px) {
      & {
        height: 80%;
        width: 80%;
      }
  `
);

import { Box, Grid, Typography } from '@mui/material';
import { Button } from 'components/Button/Button';
import * as React from 'react';
import nervosLogo from 'images/nervos-logo-white.svg';
import buildClubLogo from 'images/build_club_white.png';
import about from 'images/about.svg';
import { SectionImage } from 'theme/sectionimage';
import { CustomizedContainer } from 'theme/customizedcontainer';

export const About: React.FC = () => {
  return (
    <CustomizedContainer
      maxWidth="lg"
      sx={{
        minHeight: '90vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Grid container flexDirection="row" justifyContent="space-between" alignItems="center" flexGrow={1}>
        <Grid item xs={12} md={6}>
          <Typography variant="h1" sx={{ fontSize: '56px' }}>
            Insure <span style={{ fontSize: '56px', fontWeight: 100 }}>Protocol</span>
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ marginTop: 3, fontSize: '24px', fontWeight: 300 }}>
            Fully-collateralized permission-less insurance protocol enabling users to hedge themselves against crypto
            market volatility.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          display="flex"
          alignItems="center"
          sx={{
            justifyContent: { xs: 'center', md: 'end' },
            marginTop: { xs: '30px', md: '0px' },
          }}
        >
          <SectionImage src={about} alt="insure_logo" loading="eager" />
        </Grid>
      </Grid>
      {/* <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection={{ md: 'row', xs: 'column' }}
        marginTop={{ xs: '5rem' }}
      >
        <img src={nervosLogo} alt="Nervos network" height={90} loading="eager" />
        <img src={buildClubLogo} alt="Build club" height={100} loading="eager" />
      </Box> */}
    </CustomizedContainer>
  );
};

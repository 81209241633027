import { Box, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { features } from 'content/Features';
import { CustomizedContainer } from 'theme/customizedcontainer';
import { CircleCard } from 'components/CircleCard/CircleCard';

export const Features: React.FC = () => {
  return (
    <Box bgcolor="secondary.main">
      <CustomizedContainer maxWidth="lg">
        <Box marginBottom={5}>
          <Typography variant="h1" sx={{ textAlign: 'center' }}>
            Features
          </Typography>
        </Box>
        <Grid container spacing={8}>
          {features.map((item, index) => (
            <Grid key={`key-${index}`} item xl={4} md={6} xs={12} display="flex" justifyContent="center">
              <CircleCard item={item} />
            </Grid>
          ))}
        </Grid>
      </CustomizedContainer>
    </Box>
  );
};

import hedge from 'images/Usecases/hedge.svg';
import speculation from 'images/Usecases/speculation.svg';
import decentralized from 'images/Usecases/decentralized.svg';

export const usecases = [
  {
    header: 'DeFi Users',
    text: 'DeFi users can have peace of mind by protecting themselves from drastic devaluation of their crypto assets.',
    image: decentralized,
  },
  {
    header: 'Borrowers',
    text: 'Borrowers can buy insurance to protect their collateral from getting liquidated in a volatile market.',
    image: hedge,
  },
  {
    header: 'Speculator',
    text: 'Speculators can either buy insurance or provide liquidity by assessing the price movement of the crypto asset.',
    image: speculation,
  },
];

import custody from 'images/Benefits/custody.svg';
import diversification from 'images/Benefits/diversification.svg';
import settings from 'images/Benefits/settings.svg';
import customize from 'images/Benefits/customize.svg';
import capital from 'images/Benefits/capital.svg';
import engineering from 'images/Benefits/engineering.svg';
import trust from 'images/Benefits/trust.svg';
import transaction from 'images/Benefits/transaction.svg';
import discussion from 'images/Benefits/discussion.svg';

export const features = [
  {
    header: 'Non-custodial',
    text: 'Capital managed by an on-chain immutable smart contract',
    image: custody,
  },
  {
    header: 'Multi-asset Pool',
    text: 'Single liquidity pool for all assets to diversify the risk',
    image: diversification,
  },
  // {
  //   header: 'Automatic Payouts',
  //   text: 'Payouts are processed automatically in case of occurrence of a covered event',
  //   image: settings,
  // },
  // {
  //   header: 'Customizable',
  //   text: 'Set your own policy period',
  //   image: customize,
  // },
  {
    header: 'Fully Collateralized',
    text: 'Payouts are fully collateralized removing capital risk',
    image: capital,
  },
  // {
  //   header: 'Interoperable',
  //   text: 'Payouts can be bridged to other networks through Force Bridge',
  //   image: engineering,
  // },
  {
    header: 'Trustless',
    text: 'Fully decentralized on-chain trustless settlement',
    image: trust,
  },
  {
    header: 'Instant transactions',
    text: 'Insure is built on fast and scalable blockchain',
    image: transaction,
  },
  {
    header: 'No KYC',
    text: '100% on-chain transactions. No KYC required',
    image: discussion,
  },
];

import { Box, Grid, Link } from '@mui/material';
import { Button } from 'components/Button/Button';
import { Button as MuiButton } from '@mui/material';
import * as React from 'react';
import insureLogo from 'images/insure_logo.svg';

export const Header: React.FC = () => {
  return (
    <Box>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ padding: { md: '32px 128px', xs: '16px 20px' } }}
      >
        <Grid>
          <img src={insureLogo} width="38" height="38" alt="insure_logo" loading="eager" />
        </Grid>
        <Grid item>
          <MuiButton
            variant="text"
            LinkComponent={Link}
            href="/Insure_Whitepaper.pdf"
            target="_blank"
            sx={{ textTransform: 'none', fontWeight: 400, fontSize: '16px', color: 'white', mr: 4 }}
          >
            Whitepaper
          </MuiButton>
          <Button
            LinkComponent={Link}
            href="https://staging.insurefinance.xyz"
            label="Launch App"
            sx={{ textTransform: 'none', fontWeight: 400 }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

import { Typography } from '@mui/material';
import * as React from 'react';
import { Timeline } from 'components/Timeline/Timeline';
import { roadmap } from 'content/Roadmap';
import { CustomizedContainer } from 'theme/customizedcontainer';

export const Roadmap: React.FC = () => {
  return (
    <CustomizedContainer maxWidth="md">
      <Typography variant="h1" sx={{ textAlign: 'center', marginBottom: 5 }}>
        Roadmap
      </Typography>
      <Timeline item={roadmap} />
    </CustomizedContainer>
  );
};

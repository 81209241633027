import { Link } from '@mui/material';
import { social } from 'content/Social';
import * as React from 'react';
import { FixedNav } from './styled';

export const Social: React.FC = () => {
  return (
    <FixedNav>
      {social.map((item, index) => (
        <Link key={`${item}-${index}`} href={item.link} target="_blank">
          <img src={item.image} alt={`${item.image}-icon`} />
        </Link>
      ))}
    </FixedNav>
  );
};

import { Box, styled } from '@mui/material';

export const FixedNav = styled(Box)(
  () => `
    position: fixed;
    right: 1rem;
    bottom: 1rem;
    background: rgba(14, 16, 20, 1);
    padding: 0.75rem 2rem;
    display: flex;
    gap: 20px;
    border-radius: 50px;
    img {
      width: 27px;
      &:hover {
        cursor: pointer;
      }
    }
  `
);

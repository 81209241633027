import { ThemeProvider } from '@mui/material';
import { Header } from 'sections/Header/Header';
import { About } from 'sections/About/About';
import { defaultTheme } from 'theme/theme';
import { Features } from 'sections/Features/Features';
import { Usecases } from 'sections/Usecases/Usecases';
import { Roadmap } from 'sections/Roadmap/Roadmap';
import { Social } from 'components/Social/Social';
import { Ecosystem } from 'sections/Ecosystem/Ecosystem';
import { graphql, HeadProps } from 'gatsby';

type DataProps = {
  site: {
    siteMetadata: {
      title: string;
      description: string;
      twitterUsername: string;
      image: string;
      siteUrl: string;
    };
  };
};

function Index() {
  return (
    <>
      <ThemeProvider theme={defaultTheme}>
        <Header />
        <About />
        {/* <Participation /> */}
        {/* <Mission /> */}
        <Features />
        <Ecosystem />
        {/* <Token /> */}
        {/* <Protocol /> */}
        <Usecases />
        {/* <Governance /> */}
        <Roadmap />
        <Social />
      </ThemeProvider>
    </>
  );
}

export default Index;

export const Head = (props: HeadProps<DataProps>) => {
  return (
    <>
      {/* title */}
      <title>{props.data.site.siteMetadata.title}</title>
      <meta property="og:title" content={props.data.site.siteMetadata.title} />
      <meta name="twitter:title" content={props.data.site.siteMetadata.title} />

      {/* url */}
      <link rel="canonical" href={props.data.site.siteMetadata.siteUrl} />
      <meta property="og:url" content={props.data.site.siteMetadata.siteUrl} />

      {/* description */}
      <meta name="description" content={props.data.site.siteMetadata.description} />
      <meta property="og:description" content={props.data.site.siteMetadata.description} />
      <meta name="twitter:description" content={props.data.site.siteMetadata.description} />

      {/* image */}
      <meta property="og:image" content={props.data.site.siteMetadata.image} />
      <meta name="twitter:image" content={props.data.site.siteMetadata.image} />

      <meta property="og:type" content="website" />
      <meta name="twitter:site" content={props.data.site.siteMetadata.twitterUsername} />
      <meta name="twitter:card" content="summary_large_image" />
    </>
  );
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        twitterUsername
        image
        siteUrl
      }
    }
  }
`;

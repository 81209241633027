import { Box, Grow, Paper, Typography } from '@mui/material';
import { useIntersection } from 'hooks/useIntersection';
import * as React from 'react';
import { useRef } from 'react';
import { CircleImage } from './styled';

interface CircleCardItem {
  header: string;
  image: string;
  text?: string;
}

interface CircleCardProps {
  item: CircleCardItem;
}

export const CircleCard: React.FC<CircleCardProps> = (props) => {
  const { item } = props;

  const ref = useRef();
  const inViewport = useIntersection(ref, '-50px');

  return (
    <Grow in={inViewport} timeout={1000}>
      <Paper sx={{ padding: '40px 25px', backgroundColor: '#23262f', width: '100%', maxWidth: '270px' }}>
        <Box ref={ref} display="flex" justifyContent="center" marginBottom={3}>
          <CircleImage src={item.image} />
        </Box>
        <Typography variant="h3" textAlign="center" sx={{ fontSize: '22px' }}>
          {item.header}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ marginTop: 1, fontSize: '17px' }} textAlign="center">
          {item.text}
        </Typography>
      </Paper>
    </Grow>
  );
};

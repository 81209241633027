import { Container, styled } from '@mui/material';

export const CustomizedContainer = styled(Container)(
  ({ theme }) => `
    padding-top: 5rem;
    padding-bottom: 5rem;
    @media only screen and (max-width: 1670px) {
        & {
            padding-left: 5rem;
            padding-right: 5rem;
        }
    }
    @media only screen and (max-width: 900px) {
        & {
            padding-left: 2rem;
            padding-right: 2rem;
        }
        .MuiTypography-root {
            text-align: center;
        }
    }
    @media only screen and (max-width: 480px) {
      & {
          padding-left: 1rem;
          padding-right: 1rem;
      }
  }
  `
);

import { useMediaQuery } from '@mui/material';
import * as React from 'react';
import { TimelineElem } from './TimelineElem';
import { CustomizedTimeline } from './styled';

interface TimelineItemProps {
  header: string;
  content: string[];
}

interface TimelineProps {
  item: TimelineItemProps[];
}

export const Timeline: React.FC<TimelineProps> = (props) => {
  const { item } = props;

  // @ts-ignore
  const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <CustomizedTimeline>
      {item.map((item, index) => (
        <TimelineElem key={`key-${index}`} item={item} index={index} />
      ))}
    </CustomizedTimeline>
  );
};
